<template>
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path  d="M9 18q-.825 0-1.412-.587T7 16V4q0-.825.588-1.412T9 2h9q.825 0 1.413.588T20 4v12q0 .825-.587 1.413T18 18zm0-2h9V4H9zm-4 6q-.825 0-1.412-.587T3 20V6h2v14h11v2zm4-6V4z"/></svg>      

  </template>
  
  <script>
  
  </script>



