<template>
  
  <h2>404</h2>
  <h2>Not Found</h2>
</template>

<script setup>

</script>

<style>

</style>