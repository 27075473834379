// userService.js
import axios from 'axios';

const API_URL = 'https://assist-om.com/bio/public/api';

// https://assist-om.com/bio/public/api/profile/hkim

export const getUserByNickname = async (nickname) => {
  try {
    

    const response = await axios.get(`${API_URL}/profile/${nickname}`, {
      headers: {
        'Accept': 'application/json',
      },
    });
    console.log(`res----------->${response.data}`)
    return response.data.data;
    } catch (error) {
    console.error('Error fetching user:', error.message);
    throw error;
  }
};

