<template>
<router-view />
</template>

<style>

:root {
  --text: #003258;
  --background: #fdfef7;
}
  
  
  
  *{
    padding: 0;
    margin: 0;
  }
  body{
    background-color: var(--background);
    color: var(--text);
  }
  #app {
    font-family: EnFont, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    box-sizing: border-box;
  }
  
</style>
