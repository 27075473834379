export function downloadToFile(content, filename, contentType) {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
}

export function makeVCard({
  name,
  title,
  phone,
  address,
  email,
  previewImageSrc,
}) {
  return `BEGIN:VCARD
VERSION:3.0
N:${name}
TITLE:${title}
PHOTO;TYPE=JPEG;ENCODING=b:[${previewImageSrc}]
TEL;TYPE=WORK,VOICE:${phone}
ADR;TYPE=WORK,PREF:;;${address}
EMAIL:${email}
REV:${new Date().toISOString()}
END:VCARD`;
}
