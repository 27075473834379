
<template>

<svg viewBox="0 0 86 98" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero"><path d="M82.2 23.1c-10.3 0-18.7-8.4-18.7-18.7 0-2.1-1.7-3.8-3.8-3.8H45.3c-.5 0-1 .1-1.5.3-1.4.6-2.3 1.9-2.3 3.5v61.8c0 4.1-2.6 7.6-6.5 8.9-1 .3-2 .5-3 .5-5.2 0-9.4-4.2-9.4-9.4s4.2-9.4 9.4-9.4c2.1 0 3.8-1.7 3.8-3.8V38.6c0-2.1-1.7-3.8-3.8-3.8-8.4 0-16.3 3.3-22.2 9.2C3.9 49.9.6 57.8.6 66.2c0 8.4 3.3 16.3 9.2 22.2 5.9 5.9 13.8 9.2 22.2 9.2 1.6 0 3.2-.1 4.7-.3 6.7-1 12.7-4.1 17.5-8.9 1.5-1.5 1.5-3.9 0-5.4-1.5-1.5-3.9-1.5-5.4 0-3.6 3.6-8.2 6-13.3 6.7-1.2.2-2.4.3-3.6.3-6.4 0-12.4-2.5-16.9-7-4.5-4.5-7-10.5-7-16.9 0-6.4 2.5-12.4 7-16.9 3.6-3.6 8.1-5.9 13.1-6.7v7c-7.6 1.7-13.2 8.5-13.2 16.6 0 9.4 7.6 17 17 17 1.8 0 3.6-.3 5.3-.9 6.9-2.3 11.6-8.8 11.7-16.1v-58H56c1.7 11.4 10.8 20.5 22.2 22.2v6.9c-3.1-.4-6.2-1.2-9.1-2.4-2.6-1.1-5.1-2.5-7.3-4.2-1.1-.9-2.7-1-4-.4-1.3.6-2.1 2-2.1 3.4v32.6c0 2.1 1.7 3.8 3.8 3.8 2.1 0 3.8-1.7 3.8-3.8V40.4c.9.5 1.9.9 2.9 1.3 5 2.1 10.4 3.2 15.9 3.2 2.1 0 3.8-1.7 3.8-3.8V26.7c.1-1.9-1.6-3.6-3.7-3.6z"></path></g></svg>
</template>

<script>
export default {
  name: 'IconParkOutlineFacebookOne'
}
</script>