<template>
    <div class="details">
    <BTNSheet/>    
      <div v-for="(item, index) in props.user.others" :key="index">
      <div v-if="item.link" ><a :href="item.link">{{ item.name }}</a></div>
      </div>
    </div>
      
    
    </template>
    
    <script setup>
import BTNSheet from '@/components/BTNSheet.vue'

    const props = defineProps(['user'])


    </script>
    
    <style scoped>
   
    
    .details{
    padding: 0px 20px;
    }
    
    
    .details div div {
      border-radius: 7px;
      padding: 10px;
      margin-bottom: 20px;
      color:  var(--text);
      border: 1.5px solid rgb(212, 212, 212);
    }
    </style>
    