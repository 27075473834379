<template>
  <div>
    <button type="button" @click="makeVCard">Contact Me</button>
  </div>
</template>

<script>
import { downloadToFile, makeVCard, openVCard } from "./utils";

export default {
  data() {
    return {};
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    makeVCard() {
      try {
        const vCardContent = makeVCard({
          name: this.user.name,
          email: this.user.socialMedia.email,
          phone: this.user.mobile,
          image: null,
          title: this.user.note,
        });

        downloadToFile(vCardContent, "vcard.vcf", "text/vcard");
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
div {
  margin: 20px;
}

button {
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  color: var(--background);
  background-color: var(--text);
  border: 1.5px solid var(--text);
}
</style>
