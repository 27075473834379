<template>
  <div class="avatar" :style="{ backgroundImage: `url('${bgImage}')` }">
    <div class="img"><img :src="image" alt="Image" loading="lazy" /></div>
  </div>
  <div>
    <h1>{{ user.name }}</h1>
    <p>{{ user.note }}</p>
  </div>
</template>

<script setup>
const props = defineProps(["user"]);

const bgImage =  props.user.bgImage;
const image = props.user.image;
</script>
<style scope>
.avatar {
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: start;

  margin-bottom: 120px;
  background-repeat: no-repeat;
  background-size: cover;
}

.img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  outline: 3px solid white;
  position: absolute;
  top: 80px;
  background-color: var(--text);
}
.icon svg {
  width: 25px;
  margin: 10px 0;
}
.img img {
  width: 100%;
}

.profile h1 {
  font-size: 18px;
  font-weight: 500;
  direction: rtl;
  font-weight: bold;
}
.profile p,
.profile h1 {
  font-family: "Rubik", sans-serif;

  direction: rtl;
}
.profile p {
  opacity: 0.6;
  width: 75%;
  font-size: 14px;
  font-weight: 500;
  margin: 10px auto;
  text-align: center;
}
</style>
