
<template>

<svg viewBox="0 0 97 97" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero"><path d="M93 66.5c2.1 0 3.8-1.7 3.8-3.8V15.2C96.8 6.9 90 .1 81.7.1H15.3C7 .1.2 6.9.2 15.2v66.4c0 8.3 6.8 15.1 15.1 15.1h66.4c8.3 0 15.1-6.8 15.1-15.1 0-2.1-1.7-3.8-3.8-3.8-2.1 0-3.8 1.7-3.8 3.8 0 4.2-3.4 7.5-7.5 7.5H63.2V61.6h8.7c1.9 0 3.5-1.4 3.7-3.3l.5-4.1c.3-2.2-1.5-4.2-3.7-4.2h-9.2V37.4c0-3.1 2.5-5.7 5.7-5.7h4.7c2.1 0 3.8-1.7 3.8-3.8V24c0-1.9-1.5-3.6-3.4-3.8-1.9-.2-3.9-.4-5.1-.4-4.4 0-8.8 1.9-12.1 5.1-3.4 3.3-5.3 7.7-5.3 12.3v12.6H43c-2.1 0-3.8 1.7-3.8 3.8v4.1c0 2.1 1.7 3.8 3.8 3.8h8.5V89H15.3c-4.2 0-7.5-3.4-7.5-7.5V15.1c0-4.2 3.4-7.5 7.5-7.5h66.4c4.2 0 7.5 3.4 7.5 7.5v47.5c0 2.2 1.7 3.9 3.8 3.9z" fill-rule="nonzero"></path></g></svg>
</template>

<script>
export default {
  name: 'IconParkOutlineFacebookOne'
}
</script>