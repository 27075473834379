<template>
  <div class="share-icon" @click="open">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor" stroke-width="1.5">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M18 22a3 3 0 1 0 0-6a3 3 0 0 0 0 6m0-14a3 3 0 1 0 0-6a3 3 0 0 0 0 6M6 15a3 3 0 1 0 0-6a3 3 0 0 0 0 6"
        />
        <path d="m15.5 6.5l-7 4m0 3l7 4" />
      </g>
    </svg>
  </div>

  <vue-bottom-sheet ref="myBottomSheet" max-width="100%">
    <div class="body">
      <div class="share">
        <p>{{ urlPath }}</p>
        <div class="icon">
          <div v-if="!copied">
            <CopyIconVue @click="copyToClipboard" />
          </div>
          <div v-else>
            <DoneCopyIconVue @click="copyToClipboard" />
          </div>
        </div>
      </div>

      <div class="items" @click="openWhatsApp">
        <div>
          <WhatsappIconVue />
          <p>Share with Whatsapp</p>
        </div>
        <div class="icon">
          <BackIconVue />
        </div>
      </div>

      <div class="items" @click="openX">
        <div>
          <XIconVue />
          <p>Share with X</p>
        </div>

        <div class="icon">
          <BackIconVue />
        </div>
      </div>

      <a :href="whatsappLink">
        <div class="me">
          <p>Contact To Start</p>
        </div>
      </a>
    </div>
  </vue-bottom-sheet>
</template>

<script setup>
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import { ref } from "vue";
import BackIconVue from "@/assets/icons/BackIcon.vue";
import CopyIconVue from "@/assets/icons/CopyIcon.vue";
import DoneCopyIconVue from "@/assets/icons/DoneCopyIcon.vue";
import WhatsappIconVue from "@/assets/icons/WhatsappIcon.vue";
import XIconVue from "@/assets/icons/XIcon.vue";

const myBottomSheet = ref(null);
const urlPath = ref("");
const whatsAppUrl = ref("");
const xAppUrl = ref("");
const whatsappLink = "https://wa.me/0096877299212";

const copied = ref(false);

const copyToClipboard = () => {
  const urlToCopy = window.location.href;

  const textArea = document.createElement("textarea");
  textArea.value = urlToCopy;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
  copied.value = true;
  console.log("URL copied to clipboard");
};

const open = () => {
  urlPath.value = window.location.href;
  myBottomSheet.value.open();
};

const openX = () => {
  xAppUrl.value =
    "http://www.x.com/share?url=" + encodeURIComponent(urlPath.value);
  openNewWindow(xAppUrl.value);
};

const openWhatsApp = () => {
  whatsAppUrl.value =
    "https://wa.me/?text=" + encodeURIComponent(urlPath.value);
  openNewWindow(whatsAppUrl.value);
};

let openNewWindow = (url) => {
  window.open(url, "_blank");
};
</script>

<style scope>
button {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  color: var(--background);
  background-color: var(--text);
  border: 1.5px solid var(--text);
}
.share-icon {
  position: fixed;

  bottom: 20px;

  right: 20px;

  cursor: pointer;
}

a {
  text-decoration: none;

  color: var(--text);
  font-size: 13px;
}
.share-icon svg {
  color: #c2c2c2;
  height: 30px;
  width: 30px;
}
.body {
  margin: 20px;
}
.share p {
  color: var(--text);
  font-size: 14px;
}

.share {
  background-color: rgb(243, 243, 243);
}
.body .items,
.share {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;

  align-items: center;
  border-radius: 5px;
  margin-bottom: 15px;
}
.items {
  border: 1px solid rgb(230, 229, 229);
}
.items div {
  display: flex;
  align-items: center;
}

.me {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 30px;
  align-items: center;
  border-radius: 5px;
  background-color: var(--text);
  color: var(--background);
}
svg {
  height: 19px;
  width: 20px;
  fill: var(--text);
}
.items div p {
  padding: 0 10px;
  font-size: 14px;
}
</style>
