
<template>

<svg viewBox="0 0 97 97" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero"><path d="M40.2 28c-2.5-1.5-5.7.3-5.7 3.2v35.1c0 2.9 3.2 4.7 5.7 3.2L69.6 52c2.4-1.5 2.4-5 0-6.5L40.2 28zm1.9 31.7V37.9l18.3 10.9-18.3 10.9z"></path><path d="M94.8 21.6c-1.6-9.9-8.7-17.4-17.9-18.9C65.5.9 62.6.3 48.9.3 35.3.3 32.4.9 21 2.7c-9.1 1.4-16.3 9-17.9 18.9C1.6 30.9.9 39.6.9 48.4s.7 17.5 2.2 26.8c1.6 9.9 8.7 17.4 17.9 18.9 11.4 1.8 14.3 2.4 28 2.4 13.6 0 16.5-.6 27.9-2.4 9.1-1.4 16.3-9 17.9-18.9.1-.8.2-1.6.4-2.5 0-1.9-1.4-3.5-3.4-3.7-1.7-.2-3.2.8-3.8 2.3-.1.9-.3 1.7-.4 2.6-1 6.5-5.7 11.5-11.7 12.5-22.1 3.5-31.6 3.5-53.7 0-6-.9-10.7-5.9-11.7-12.5C9.1 65 8.4 56.7 8.4 48.4s.7-16.5 2.1-25.5c1-6.5 5.7-11.5 11.7-12.5 22.1-3.5 31.6-3.5 53.7 0 6 .9 10.7 5.9 11.7 12.5 1.4 8.9 2.1 17.2 2.1 25.5 0 2 0 4-.1 5.9.2 1.8 1.5 3.2 3.4 3.4 1.8.2 3.3-.9 3.9-2.4.1-2.3.1-4.6.1-6.9 0-8.8-.7-17.5-2.2-26.8z"></path></g></svg>
</template>

<script>
export default {
  name: 'IconParkOutlineFacebookOne'
}
</script>