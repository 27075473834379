<template>

<div class="profile"  v-if="user">
<Avatar :user="user"/>
<MedialIconsVue :socialMedia="user.socialMedia" />

<ShareContactVue :user="user" />
<OtherLink :user="user" />


</div>
  

<div v-else  class="loading"> 
<div></div>
<div></div>
<div></div>
<div></div>
</div>

</template>

<script setup >

import OtherLink from '../components/OtherLink.vue';
import Avatar from '../components/Avatar.vue';
import MedialIconsVue from '../components/MedialIcons.vue';
import { onMounted, ref } from 'vue';
import { getUserByNickname } from '../services/userData'
import ShareContactVue from '@/components/ShareContact.vue';

const props = defineProps(['nickname'])

const user = ref(null);

onMounted(async () => {
  try {
    user.value = await getUserByNickname(props.nickname);
    console.log(user.value);
  } catch (error) {

  }
});

</script>

<style scoped>
.profile{
  width: 100%;
}


@keyframes scaling{
  0%, 100%{
    transform: scale(0.2);
    background-color: #30FFB7;
  }
  40%{
    transform: scale(1);
    background-color: #07DEFF;
  }
  50%{
    transform: scale(1);
    background-color: #0761FF;
  }
}
.loading{
  margin-top:10px ;
}
.loading div{

  background-color: red;
  height: 22px;
  width: 22px;
  border-radius:50%;
  display: inline-block;
  transform: scale(0);
  margin:.5rem; 
  animation: scaling 2.5s ease-in-out infinite;
    background-color:red;
}

.loading div:nth-child(0){
  animation-delay:0s;
}
.loading div:nth-child(1){
  animation-delay:0.2s;
}
.loading div:nth-child(2){
  animation-delay:0.4s;
}
.loading div:nth-child(3){
  animation-delay:0.6s;
}
.loading div:nth-child(4){
  animation-delay:0.8s;
}
.loading div:nth-child(5){
  animation-delay:1s;
} 



</style>
