

<template>
     <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M9 16.2L4.8 12l-1.4 1.4L9 19L21 7l-1.4-1.4z"></path></svg>
   </template>
   
   <script>
   export default {
     name: 'IcTwotoneDone'
   }
   </script>