<template>
  <div class="icons">
    <div class="icon" v-if="props.socialMedia.email">
      <a @click="openemailLink">
        <EmailIconVue />
      </a>
    </div>
    <div class="icon" v-if="props.socialMedia.whatsapp">
      <a @click="openWhatsApp">
        <WhatsappIconVue />
      </a>
    </div>
    <div class="icon" v-if="props.socialMedia.instagram">
      <a @click="openinstagramLink">
        <InstagramIconVue />
      </a>
    </div>

    <div class="icon" v-if="props.socialMedia.x">
      <a @click="openxLink">
        <XIconVue />
      </a>
    </div>
    <div class="icon" v-if="props.socialMedia.snapchat">
      <a @click="openesnapchatLink">
        <SnapchatIconVue />
      </a>
    </div>

    <div class="icon" v-if="props.socialMedia.linkedin">
      <a @click="openelinkedinLink">
        <LinkedinIconVue />
      </a>
    </div>
    <div class="icon" v-if="props.socialMedia.facebook">
      <a @click="openfacebookLink">
        <FacebookIconVue />
      </a>
    </div>

    <div class="icon" v-if="props.socialMedia.ticktok">
      <a @click="openticktokLink">
        <TickTokIconVue />
      </a>
    </div>

    <div class="icon" v-if="props.socialMedia.youtube">
      <a @click="openyoutubeLink">
        <YoutubeIconVue />
      </a>
    </div>
  </div>
</template>

<script setup>
import EmailIconVue from "@/assets/icons/EmailIcon.vue";
import FacebookIconVue from "@/assets/icons/FacebookIcon.vue";
import InstagramIconVue from "@/assets/icons/InstagramIcon.vue";
import LinkedinIconVue from "@/assets/icons/LinkedinIcon.vue";
import SnapchatIconVue from "@/assets/icons/SnapchatIcon.vue";
import TickTokIconVue from "@/assets/icons/TickTokIcon.vue";
import WhatsappIconVue from "@/assets/icons/WhatsappIcon.vue";

import XIconVue from "@/assets/icons/XIcon.vue";
import YoutubeIconVue from "@/assets/icons/YoutubeIcon.vue";

const props = defineProps(["socialMedia"]);

const linkedinLink = props.socialMedia.linkedin;
const whatsappLink = props.socialMedia.whatsapp;
const instagramLink = props.socialMedia.instagram;
const xLink = props.socialMedia.x;
const snapchatLink = props.socialMedia.snapchat;
const emailLink = props.socialMedia.email;
const facebookLink = props.socialMedia.facebook;
const youtubeLink = props.socialMedia.youtube;
const ticktokLink = props.socialMedia.ticktok;

const openelinkedinLink = () => {
  openNewWindow(linkedinLink);
};

const openesnapchatLink = () => {
  openNewWindow(snapchatLink);
};
const openemailLink = () => {
  let emailUrl = "mailto:" + emailLink;
  openNewWindow(emailUrl);
};
const openticktokLink = () => {
  openNewWindow(ticktokLink);
};
const openfacebookLink = () => {
  openNewWindow(facebookLink);
};

const openyoutubeLink = () => {
  openNewWindow(youtubeLink);
};

const openxLink = () => {
  console.log(xLink);
  openNewWindow(xLink);
};
const openinstagramLink = () => {
  openNewWindow(instagramLink);
};
const openWhatsApp = () => {
  openNewWindow(whatsappLink);
};

let openNewWindow = (url) => {
  window.open(url, "_blank");
};
</script>

<style scoped>
.icons {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 20px;

  margin: 20px;
}
.icon a svg {
  height: 28px;
  width: 28px;
  fill: var(--text);
}
.icon a {
  display: flex;

  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
</style>
