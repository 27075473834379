import { createRouter, createWebHashHistory } from 'vue-router'
import PorfileView from '../views/ProfileView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import HomeViewVue from '@/views/HomeView.vue';

const routes = [
  {
    path:'/',
    name:'HomeViewVue',
    component:HomeViewVue,
  },
  {
    path:'/:nickname',
    name:'PorfileView',
    component:PorfileView,
    props:true,
  },
  {
    path:'/:catchAll(.*)',
    name:'NotFound',
    component:NotFoundView,
  }

];

const router = createRouter({
  history: createWebHashHistory(),  
  routes
})

export default router
